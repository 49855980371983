import React from "react";
import { type Dispatch } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LabelSelectable from "../LabelSelectable";
import { useNavigate } from "react-router-dom";
import MediumButton from "../MediumButton";
import LabelList from "../LabelList";
import Badge from "./Badge";
import type {
  SearchAreaCategory,
  SearchAreaCategories,
  SearchArea,
  ServiceTypeCategory,
  ServiceTypeCategories,
  ServiceType,
  OccupationTypeCategory,
  OccupationTypeCategories,
  OccupationType,
  Masters,
  Condition,
} from "~/types";
import { useSelectionCount } from "~/hooks";

type Props = {
  setVisible: Dispatch<React.SetStateAction<boolean>>;
  activeTab: number;
  masters: Masters;
  handleAddCondition: (type: keyof Condition, value: string | string[]) => void;
  handleSubCondition: (type: keyof Condition, value: string | string[]) => void;
  handleResetConditions: () => void;
  handleVisibleToFalse?: () => void;
  handleVisibleToFalseForClose?: () => void;
  selectedConditions: Condition;
  setSelectedConditions: Dispatch<React.SetStateAction<Condition>>;
  setAppliedConditions: Dispatch<React.SetStateAction<Condition>>;
  initialState: Condition;
};

const DirectSearchModal: React.FC<Props> = (props) => {
  const handleAddCondition = props.handleAddCondition;
  const handleSubCondition = props.handleSubCondition;
  const handleResetConditions = props.handleResetConditions;
  const handleVisibleToFalse = props.handleVisibleToFalse;
  const handleVisibleToFalseForClose = props.handleVisibleToFalseForClose;
  const selectedConditions = props.selectedConditions;
  const setSelectedConditions = props.setSelectedConditions;
  const setAppliedConditions = props.setAppliedConditions;
  const masters = props.masters;
  const [isSearching, setIsSearching] = React.useState(false);
  const navigate = useNavigate();
  const initialState = props.initialState;
  const activeTab = props.activeTab;
  const [tabIndex, setTabIndex] = React.useState(activeTab);
  const [initialConditions] = React.useState<Condition>(selectedConditions);
  const { serviceTypeCount, occupationTypeCount, searchAreaCount } =
    useSelectionCount(selectedConditions);

  const handleClose = () => {
    setSelectedConditions(initialConditions);
    if (handleVisibleToFalseForClose) {
      handleVisibleToFalseForClose();
    }
  };

  // クエリパラメーターを生成
  const generateQueryParams = (conditions: Condition) => {
    const params = new URLSearchParams();
    Object.keys(conditions).forEach((key) => {
      const value = conditions[key as keyof Condition];
      if (value.length > 0) {
        params.append(key, value.join(","));
      }
    });
    return params.toString();
  };

  // 検索ボタンをクリックしたときの処理
  const handleSearchClick = () => {
    setIsSearching(true);
    setAppliedConditions(selectedConditions);
    const queryParams =
      JSON.stringify(selectedConditions) === JSON.stringify(initialState)
        ? ""
        : `?${generateQueryParams(selectedConditions)}`;

    navigate(`/list${queryParams}`);
    if (handleVisibleToFalse) {
      handleVisibleToFalse();
    }
    setIsSearching(false);
  };

  const searchAreaCategories = masters.searchAreaCategories.reduce(
    (acc: SearchAreaCategories, category: SearchAreaCategory) => {
      acc[category.key] = category;
      return acc;
    },
    {}
  );
  const searchAreas = masters.searchAreas;
  const searchAreasForTokyo = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id === searchAreaCategories["tokyo"]["id"]
  );
  const searchAreasForKanto = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id === searchAreaCategories["kanto"]["id"]
  );
  const searchAreasForKansai = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id === searchAreaCategories["kansai"]["id"]
  );
  const searchAreasForSearchAreaCategoryOther = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id ===
      searchAreaCategories["search-area-category-other"]["id"]
  );

  const serviceTypeCategories = masters.serviceTypeCategories.reduce(
    (acc: ServiceTypeCategories, category: ServiceTypeCategory) => {
      acc[category.key] = category;
      return acc;
    },
    {}
  );
  const serviceTypes = masters.serviceTypes;
  const serviceTypesForJapaneseCuisine = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["japanese-cuisine"]["id"]
  );
  const serviceTypesForWesternCuisine = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["western-cuisine"]["id"]
  );
  const serviceTypesForChineseCuisine = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["chinese-cuisine"]["id"]
  );
  const serviceTypesForGrilledFriedHotpot = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["grilled-fried-hotpot"]["id"]
  );
  const serviceTypesForAlcoholicBeverages = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["alcoholic-beverages"]["id"]
  );
  const serviceTypesForSnacks = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id === serviceTypeCategories["snacks"]["id"]
  );
  const serviceTypesForEthnicCuisine = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["ethnic-cuisine"]["id"]
  );
  const serviceTypesForNoodlesBowlsFastFood = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["noodles-bowls-fast-food"]["id"]
  );
  const serviceTypesForFacilities = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["facilities"]["id"]
  );
  const serviceTypesForFoodManufacturingSales = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["food-manufacturing-sales"]["id"]
  );
  const serviceTypesForAmusement = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id === serviceTypeCategories["amusement"]["id"]
  );
  const serviceTypesForServiceTypeCategoryOther = serviceTypes.filter(
    (type: ServiceType) =>
      type.service_type_category_id ===
      serviceTypeCategories["service-type-category-other"]["id"]
  );

  const occupationTypeCategories = masters.occupationTypeCategories.reduce(
    (acc: OccupationTypeCategories, category: OccupationTypeCategory) => {
      acc[category.key] = category;
      return acc;
    },
    {}
  );
  const occupationTypes = masters.occupationTypes;
  const occupationTypesForStoreOperationsCustomerService =
    occupationTypes.filter(
      (type: OccupationType) =>
        type.occupation_type_category_id ===
        occupationTypeCategories["store-operations-customer-service"]["id"]
    );
  const occupationTypesForStoreOperationsCooking = occupationTypes.filter(
    (type: OccupationType) =>
      type.occupation_type_category_id ===
      occupationTypeCategories["store-operations-cooking"]["id"]
  );
  const occupationTypesForStoreOperationsSpecialist = occupationTypes.filter(
    (type: OccupationType) =>
      type.occupation_type_category_id ===
      occupationTypeCategories["store-operations-specialist"]["id"]
  );
  const occupationTypesForStoreOperationsOther = occupationTypes.filter(
    (type: OccupationType) =>
      type.occupation_type_category_id ===
      occupationTypeCategories["store-operations-other"]["id"]
  );
  const occupationTypesForFactoryCentralKitchen = occupationTypes.filter(
    (type: OccupationType) =>
      type.occupation_type_category_id ===
      occupationTypeCategories["factory-central-kitchen"]["id"]
  );
  const occupationTypesForHeadquartersOperationsManagement =
    occupationTypes.filter(
      (type: OccupationType) =>
        type.occupation_type_category_id ===
        occupationTypeCategories["headquarters-operations-management"]["id"]
    );
  const occupationTypesForHeadquartersDevelopment = occupationTypes.filter(
    (type: OccupationType) =>
      type.occupation_type_category_id ===
      occupationTypeCategories["headquarters-development"]["id"]
  );
  const occupationTypesForHeadquartersFoodProductManagement =
    occupationTypes.filter(
      (type: OccupationType) =>
        type.occupation_type_category_id ===
        occupationTypeCategories["headquarters-food-product-management"]["id"]
    );
  const occupationTypesForHeadquartersTechnicalSpecialist =
    occupationTypes.filter(
      (type: OccupationType) =>
        type.occupation_type_category_id ===
        occupationTypeCategories["headquarters-technical-specialist"]["id"]
    );
  const occupationTypesForHeadquartersAdministrationBackOffice =
    occupationTypes.filter(
      (type: OccupationType) =>
        type.occupation_type_category_id ===
        occupationTypeCategories["headquarters-administration-back-office"][
          "id"
        ]
    );
  const occupationTypesForExecutiveManagementSalesManagement =
    occupationTypes.filter(
      (type: OccupationType) =>
        type.occupation_type_category_id ===
        occupationTypeCategories["executive-management-sales-management"]["id"]
    );

  return (
    <div className="fixed inset-0 overflow-auto z-50 opacity-0 transition-opacity duration-500 group-[.entered]:opacity-100 overscroll-contain">
      <div className="fixed inset-0 bg-black/10"></div>
      <div className="relative bg-white min-h-[100vh_+_1px]">
        <button className="fixed top-0 right-0 z-10 md:top-10 md:right-10 lg:right-14">
          <span
            className="inline-block aspect-square sp:p-4"
            onClick={handleClose}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleClose();
              }
            }}
          >
            <span className="grid place-content-center w-10 aspect-square rounded-full bg-muted-dark">
              <svg className="block w-[14px] h-[14px] mx-auto fill-white">
                <use xlinkHref="/symbol-defs.svg#icon-delete"></use>
              </svg>
            </span>
          </span>
        </button>
        <div className="min-h-screen pt-[72px] px-4 pb-44 md:max-w-[1232px] md:mx-auto md:pt-24 md:px-24">
          <div className="relative -mb-10 text-center z-10 md:-mb-14">
            <h2 className="inline-block text-[32px] leading-normal font-black tracking-wider md:text-5xl">
              求人検索
            </h2>
          </div>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList className="sticky top-0 grid grid-cols-3 mb-10 pt-[72px] bg-white md:pt-24">
              <Tab className="cursor-pointer [&_span]:aria-selected:bg-secondary [&_p]:aria-selected:text-secondary [&>div]:aria-selected:border-secondary">
                <div className="relative pt-2 pb-1 px-1 border-b-4 border-muted-dark text-center md:flex md:items-center md:justify-center md:pt-5 md:pb-4">
                  <span className="flex items-center justify-center w-10 aspect-square rounded-full bg-muted-dark sp:mx-auto md:mr-4">
                    <svg className="w-6 aspect-square fill-white">
                      <use xlinkHref="/symbol-defs.svg#icon-bowl"></use>
                    </svg>
                  </span>
                  <p className="text-muted-dark text-lg font-bold leading-[1.8] md:text-xl lg:text-2xl">
                    業態
                    <small className="text-xl font-bold hidden lg:inline">
                      を選択
                    </small>
                    {serviceTypeCount > 0 && <Badge>{serviceTypeCount}</Badge>}
                  </p>
                </div>
              </Tab>
              <Tab className="cursor-pointer [&_span]:aria-selected:bg-primary [&_p]:aria-selected:text-primary [&>div]:aria-selected:border-primary">
                <div className="relative pt-2 pb-1 px-1 border-b-4 border-muted-dark text-center md:flex md:items-center md:justify-center md:pt-5 md:pb-4">
                  <span className="flex items-center justify-center w-10 aspect-square rounded-full bg-muted-dark sp:mx-auto md:mr-4">
                    <svg className="w-6 aspect-square fill-white">
                      <use xlinkHref="/symbol-defs.svg#icon-shirt"></use>
                    </svg>
                  </span>
                  <p className="text-muted-dark text-lg font-bold leading-[1.8] md:text-xl lg:text-2xl">
                    職種
                    <small className="text-xl font-bold hidden lg:inline">
                      を選択
                    </small>
                    {occupationTypeCount > 0 && (
                      <Badge>{occupationTypeCount}</Badge>
                    )}
                  </p>
                </div>
              </Tab>
              <Tab className="cursor-pointer [&_span]:aria-selected:bg-tertiary [&_p]:aria-selected:text-tertiary [&>div]:aria-selected:border-tertiary">
                <div className="relative pt-2 pb-1 px-1 border-b-4 border-muted-dark text-center md:flex md:items-center md:justify-center md:pt-5 md:pb-4">
                  <span className="flex items-center justify-center w-10 aspect-square rounded-full bg-muted-dark sp:mx-auto md:mr-4">
                    <svg className="w-6 aspect-square fill-white">
                      <use xlinkHref="/symbol-defs.svg#icon-pin"></use>
                    </svg>
                  </span>
                  <p className="text-muted-dark text-lg font-bold leading-[1.8] md:text-xl lg:text-2xl">
                    エリア
                    <small className="text-xl font-bold hidden lg:inline">
                      を選択
                    </small>
                    {searchAreaCount > 0 && <Badge>{searchAreaCount}</Badge>}
                  </p>
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="min-h-[calc(100vh_-_176px)]">
                <LabelList>
                  <dt>{serviceTypeCategories["japanese-cuisine"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForJapaneseCuisine.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForJapaneseCuisine.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{serviceTypeCategories["western-cuisine"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForWesternCuisine.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForWesternCuisine.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{serviceTypeCategories["chinese-cuisine"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForChineseCuisine.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForChineseCuisine.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {serviceTypeCategories["grilled-fried-hotpot"]["name"]}
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForGrilledFriedHotpot.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForGrilledFriedHotpot.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {serviceTypeCategories["alcoholic-beverages"]["name"]}
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForAlcoholicBeverages.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForAlcoholicBeverages.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{serviceTypeCategories["snacks"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForSnacks.map((type) => type.id)}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForSnacks.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{serviceTypeCategories["ethnic-cuisine"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForEthnicCuisine.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForEthnicCuisine.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {serviceTypeCategories["noodles-bowls-fast-food"]["name"]}
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForNoodlesBowlsFastFood.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForNoodlesBowlsFastFood.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{serviceTypeCategories["facilities"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForFacilities.map((type) => type.id)}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForFacilities.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {serviceTypeCategories["food-manufacturing-sales"]["name"]}
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForFoodManufacturingSales.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForFoodManufacturingSales.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{serviceTypeCategories["amusement"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForAmusement.map((type) => type.id)}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForAmusement.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      serviceTypeCategories["service-type-category-other"][
                        "name"
                      ]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="secondary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="service_type_ids"
                      value={serviceTypesForServiceTypeCategoryOther.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {serviceTypesForServiceTypeCategoryOther.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="secondary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="service_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="min-h-[calc(100vh_-_176px)]">
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories[
                        "store-operations-customer-service"
                      ]["name"]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForStoreOperationsCustomerService.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForStoreOperationsCustomerService.map(
                      (type) => (
                        <LabelSelectable
                          key={type.id}
                          text={type.name}
                          labelColor="primary"
                          handleAddCondition={handleAddCondition}
                          handleSubCondition={handleSubCondition}
                          type="occupation_type_ids"
                          value={type.id}
                          selectedConditions={selectedConditions}
                        ></LabelSelectable>
                      )
                    )}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories["store-operations-cooking"][
                        "name"
                      ]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForStoreOperationsCooking.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForStoreOperationsCooking.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="primary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="occupation_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories["store-operations-specialist"][
                        "name"
                      ]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForStoreOperationsSpecialist.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForStoreOperationsSpecialist.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="primary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="occupation_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {occupationTypeCategories["store-operations-other"]["name"]}
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForStoreOperationsOther.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForStoreOperationsOther.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="primary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="occupation_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories["factory-central-kitchen"][
                        "name"
                      ]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForFactoryCentralKitchen.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForFactoryCentralKitchen.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="primary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="occupation_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories[
                        "headquarters-operations-management"
                      ]["name"]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForHeadquartersOperationsManagement.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForHeadquartersOperationsManagement.map(
                      (type) => (
                        <LabelSelectable
                          key={type.id}
                          text={type.name}
                          labelColor="primary"
                          handleAddCondition={handleAddCondition}
                          handleSubCondition={handleSubCondition}
                          type="occupation_type_ids"
                          value={type.id}
                          selectedConditions={selectedConditions}
                        ></LabelSelectable>
                      )
                    )}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories["headquarters-development"][
                        "name"
                      ]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForHeadquartersDevelopment.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForHeadquartersDevelopment.map((type) => (
                      <LabelSelectable
                        key={type.id}
                        text={type.name}
                        labelColor="primary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="occupation_type_ids"
                        value={type.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories[
                        "headquarters-food-product-management"
                      ]["name"]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForHeadquartersFoodProductManagement.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForHeadquartersFoodProductManagement.map(
                      (type) => (
                        <LabelSelectable
                          key={type.id}
                          text={type.name}
                          labelColor="primary"
                          handleAddCondition={handleAddCondition}
                          handleSubCondition={handleSubCondition}
                          type="occupation_type_ids"
                          value={type.id}
                          selectedConditions={selectedConditions}
                        ></LabelSelectable>
                      )
                    )}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories[
                        "headquarters-technical-specialist"
                      ]["name"]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForHeadquartersTechnicalSpecialist.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForHeadquartersTechnicalSpecialist.map(
                      (type) => (
                        <LabelSelectable
                          key={type.id}
                          text={type.name}
                          labelColor="primary"
                          handleAddCondition={handleAddCondition}
                          handleSubCondition={handleSubCondition}
                          type="occupation_type_ids"
                          value={type.id}
                          selectedConditions={selectedConditions}
                        ></LabelSelectable>
                      )
                    )}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories[
                        "headquarters-administration-back-office"
                      ]["name"]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForHeadquartersAdministrationBackOffice.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForHeadquartersAdministrationBackOffice.map(
                      (type) => (
                        <LabelSelectable
                          key={type.id}
                          text={type.name}
                          labelColor="primary"
                          handleAddCondition={handleAddCondition}
                          handleSubCondition={handleSubCondition}
                          type="occupation_type_ids"
                          value={type.id}
                          selectedConditions={selectedConditions}
                        ></LabelSelectable>
                      )
                    )}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {
                      occupationTypeCategories[
                        "executive-management-sales-management"
                      ]["name"]
                    }
                  </dt>
                  <dd>
                    <LabelSelectable
                      text="全て選択"
                      labelColor="primary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="occupation_type_ids"
                      value={occupationTypesForExecutiveManagementSalesManagement.map(
                        (type) => type.id
                      )}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {occupationTypesForExecutiveManagementSalesManagement.map(
                      (type) => (
                        <LabelSelectable
                          key={type.id}
                          text={type.name}
                          labelColor="primary"
                          handleAddCondition={handleAddCondition}
                          handleSubCondition={handleSubCondition}
                          type="occupation_type_ids"
                          value={type.id}
                          selectedConditions={selectedConditions}
                        ></LabelSelectable>
                      )
                    )}
                  </dd>
                </LabelList>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="min-h-[calc(100vh_-_176px)]">
                <LabelList>
                  <dt>{searchAreaCategories["tokyo"]["name"]}</dt>
                  <dd>
                    <LabelSelectable
                      text="東京都全域"
                      labelColor="tertiary"
                      handleAddCondition={handleAddCondition}
                      handleSubCondition={handleSubCondition}
                      type="search_area_ids"
                      value={searchAreasForTokyo.map((area) => area.id)}
                      selectedConditions={selectedConditions}
                    ></LabelSelectable>
                    {searchAreasForTokyo.map((area) => (
                      <LabelSelectable
                        key={area.id}
                        text={area.name}
                        labelColor="tertiary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="search_area_ids"
                        value={area.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{searchAreaCategories["kanto"]["name"]}</dt>
                  <dd>
                    {searchAreasForKanto.map((area) => (
                      <LabelSelectable
                        key={area.id}
                        text={area.name}
                        labelColor="tertiary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="search_area_ids"
                        value={area.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>{searchAreaCategories["kansai"]["name"]}</dt>
                  <dd>
                    {searchAreasForKansai.map((area) => (
                      <LabelSelectable
                        key={area.id}
                        text={area.name}
                        labelColor="tertiary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="search_area_ids"
                        value={area.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
                <LabelList>
                  <dt>
                    {searchAreaCategories["search-area-category-other"]["name"]}
                  </dt>
                  <dd>
                    {searchAreasForSearchAreaCategoryOther.map((area) => (
                      <LabelSelectable
                        key={area.id}
                        text={area.name}
                        labelColor="tertiary"
                        handleAddCondition={handleAddCondition}
                        handleSubCondition={handleSubCondition}
                        type="search_area_ids"
                        value={area.id}
                        selectedConditions={selectedConditions}
                      ></LabelSelectable>
                    ))}
                  </dd>
                </LabelList>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      {/* 固定ボタン start */}
      <div className="fixed bottom-0 left-0 w-full bg-white">
        <div className="flex justify-center gap-3 w-full p-4 md:gap-6 [&_button]:py-3.5 sp:[&_button]:w-full sp:[&_button]:text-sm md:[&_button]:min-w-[280px]">
          <MediumButton
            text="選択をクリア"
            color="gray"
            handler={handleResetConditions}
          ></MediumButton>
          <MediumButton
            text="この条件で検索する"
            handler={handleSearchClick}
            disabled={isSearching}
          ></MediumButton>
        </div>
      </div>
      {/* 固定ボタン end */}
    </div>
  );
};

export default DirectSearchModal;
