import { Condition } from "~/types";

export const useSelectionCount = (conditions: Condition) => {
  const serviceTypeCount = conditions.service_type_ids.length;
  const occupationTypeCount = conditions.occupation_type_ids.length;
  const searchAreaCount = conditions.search_area_ids.length;

  return {
    serviceTypeCount,
    occupationTypeCount,
    searchAreaCount,
  };
};
