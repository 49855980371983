import React, { ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  children: ReactNode;
};

const Badge: React.FC<Props> = ({ children }) => {
  return (
    // 中に入る数字は最高3桁
    <AnimatePresence>
      <motion.sup
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        className="inline-block ml-0.5 sp:absolute sp:top-1 sp:left-1/2 sp:translate-x-2.5 md:-top-1 md:ml-1"
      >
        <span className="grid place-items-center text-white text-[10px] font-bold min-w-5 h-5 px-1 pb-[1px] rounded-full !bg-caution md:min-w-6 md:h-6 md:text-xs">
          {children}
        </span>
      </motion.sup>
    </AnimatePresence>
  );
};

export default Badge;
